export class VoucherBase {
    public outLegMiscFee: number;
    public outLegDiscountedPrice: number;
    public outLegDiscountPercent: number;
    public rtnLegMiscFee: number;
    public rtnLegDiscountedPrice: number;
    public rtnLegDiscountPercent: number;
    public orderId: string;
    public orderMiscFee: number;
    public code: string;
    public percent: number;
    public discountedTotalPriceValue: number;
}

export class BookingOrderVoucher extends VoucherBase {
    private static readonly outLegId = 1;
    private static readonly rtnLegId = 2;

    constructor(di: DataModel.DiscountInformation, orderId: string) {
        super();

        if (di && di.currentDiscount != null) {
            this.parseVoucher(di.currentDiscount, orderId);
        }
    }

    private parseVoucher(voucher: DataModel.CurrentDiscount, orderId: string): void {
        const orders = voucher != null && voucher.orders && voucher.orders.length > 0 ? voucher.orders : null;

        if (orders != null) {
            this.code = voucher.voucherCode;
            this.percent = Number(voucher.voucherPercentValue);

            if (orderId) {
                const order = orders.find(order => order.orderId === orderId);

                if (order && order.legs && order.legs.length > 0) {
                    const outLeg = order.legs.find(leg =>
                        leg.grouping
                            ?.split("")
                            .map(id => Number(id))
                            .includes(BookingOrderVoucher.outLegId)
                    );

                    const rtnLeg = order.legs.find(leg =>
                        leg.grouping
                            ?.split("")
                            .map(id => Number(id))
                            .includes(BookingOrderVoucher.rtnLegId)
                    );

                    if (outLeg && outLeg.miscFee !== 0) {
                        this.outLegMiscFee = outLeg.miscFee;
                        this.outLegDiscountedPrice = outLeg.totalPriceValue - outLeg.miscFee; //discountedTotalPriceValue
                        this.outLegDiscountPercent = Math.round(((outLeg.miscFee * -1) / outLeg.totalPriceValue) * 100);
                    }

                    if (rtnLeg && rtnLeg.miscFee !== 0) {
                        this.rtnLegMiscFee = rtnLeg.miscFee;
                        this.rtnLegDiscountedPrice = rtnLeg.totalPriceValue - rtnLeg.miscFee; //discountedTotalPriceValue
                        this.rtnLegDiscountPercent = Math.round(((rtnLeg.miscFee * -1) / rtnLeg.totalPriceValue) * 100);
                    }

                    if (this.outLegMiscFee || this.rtnLegMiscFee) {
                        this.orderMiscFee = order.legs.reduce((a, b) => a + b.miscFee, 0);
                        this.orderId = order.orderId;
                        this.discountedTotalPriceValue = order.discountedTotalPriceValue;
                    }
                }
            }
        }
    }
}
