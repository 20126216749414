export const environment = {
    production: false,
    cmsUrl: "http://contentatestxsflauxasy.devcloud.acquia-sites.com/api",
    configUrl: "https://api-gc.dev.acetrain.co.uk/aceinfra-dev/config/gc",
    appVersionCheckUrl: "https://api-gc.dev.acetrain.co.uk/aceinfra-dev/appVersionCheck/gc",
    tocMarketingCarrierName: "GrandCentral",
    msUrl: "http://grandcentraldev.prod.acquia-sites.com/",
    braintreeAuth: process.env.NG_APP_BRAINTREE_AUTH,
    googlePayMerchantId: process.env.NG_APP_GOOGLEPAY_MERCHANTID,
    tocId: "GC",
    env: "DEV",
    sessionDuration: 10,
    logGroupName: "ace-web-TEST",
    // eslint-disable-next-line max-len
    cloudWatchParams:
        "1fccd58028477d622340eb03b0b95ec9e31ed0f980b490362ceb19349d9b7c679b5684ca9284850a8b946327b4169cd6ba28ada87b2fb463889d8e8e4c5af057967c4ce5a2c78f969ddc95dc95b046158fbce099d4e7e4d4d18a86b927d7873afb0acef9fc0d53d8eb70e23db8e9e81bd1129d26730de4660b185ae027882acc43258627b441ed6fa0d916a37f",
    carrierNames: ["Chiltern", "GrandCentral"],
    isEticketDefaultDeliveryOption: true,
    loggerEnabled: false,
    loggerRedux: false,
    showDebugUi: true,
    isDefaultPrivacyPolicyOn: true,
    isDefaultMarketingPreferenceAccepted: false,
    // For local testing, use api key instead of domain token. The domain token works only with registered domains.
    getAddressDomainToken:
        "dtoken_hEDzcyiWMr29j4oOD0DwoCnyrYGb2PjzW7ghYAzy7djHb0SzS317wI1eyhADhPZyfzU2sJaORWn_NO-9m1GNWrKcliSA51mxGcBk-tbAA2W-z7OLdFjz75wBw7AuH0d0kLuVcyv6SbC40OZJxLmaegonABQaihMJ9Prdq-6skLkRHKNWE4KHExo0o3q3W3570wckK80G1DH0CzZSLENFGi8x60QlqsJi",
    isGenericBannerOn: false,
    levels: ["debug", "error", "info", "log", "warn", "trace"],
    logrocket: {
        enabled: false,
        release: "{{commitHash}}"
    },
    e2e: {
        login: "e2e_dev_gc@yopmail.com",
        login2: "maciej.horeglad+e2e-gc-dev@ormdigital.com",
        password: "ORMRocks!1",
        newPassword: "ORMRocks!2"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import "zone.js/plugins/zone-error"; // Included with Angular CLI.
