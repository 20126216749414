import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, filter, map, mergeMap, retry, switchMap, tap } from "rxjs/operators";
import { AceSmartcard } from "../shared/models/ace/ace-smartcard.model";
import { AceAnonymousUser } from "../shared/models/ace/ace-user-anonymous.model";
import { AceUserBooking } from "../shared/models/ace/ace-user-booking";
import { AceUser } from "../shared/models/ace/ace-user.model";
import { UserLoggedIn, UserLoggedOut } from "../shared/state/account/account.actions";
import { AccountState } from "../shared/state/account/account.state";
import {
    CLAIM_ACCOUNT_REQUEST,
    DELETE_USER_ACCOUNT_REQUEST,
    EMAIL_REGISTER_REQUEST,
    EMAIL_VERIFY_REQUEST,
    RESEND_EMAIL_REQUEST,
    USER_AUTH_REQUEST,
    USER_BOOKING_REQUEST,
    USER_INIT_RESET_REQUEST,
    USER_LOGIN_REQUEST,
    USER_REGISTER_REQUEST,
    USER_RESET_REQUEST,
    USER_UPDATE_REQUEST,
    USER_VERIFY_REQUEST
} from "../shared/constants/api-calls";
import { ClearJourneyState } from "../shared/state/journey/journey.actions";
import { AceCoreApiService } from "./ace-core-api.service";
import { BasketService } from "./basket.service";
import { BookingService } from "./booking.service";
import { ConfigService } from "./config.service";
import { CredentialsSigner } from "./credentials-signer.service";
import { GoogleAnalyticsService } from "./google-analytics.service";
import { NotifyToastService } from "./notify-toast.service";
import { SessionManagementService } from "./session-management.service";

@Injectable({
    providedIn: "root"
})
export class AccountService {
    public static readonly USER_VERIFICATION_URL: string = window.location.origin + "/verifyUser";
    public static readonly EMAIL_VERIFICATION_URL: string = window.location.origin + "/verifyEmail";
    public static readonly mobileBreakpoint: number = 767;
    public isLoggedIn$: ReplaySubject<boolean> = new ReplaySubject(1);
    public currentUser$: BehaviorSubject<AceUser> = new BehaviorSubject<AceUser>(null);
    public mobileNavExpanded$ = new BehaviorSubject<boolean>(false);
    public mobileMenu$ = new BehaviorSubject<boolean>(false);

    constructor(
        private aceCoreApi: AceCoreApiService,
        private bookingService: BookingService,
        private basketService: BasketService,
        private credentialsSigner: CredentialsSigner,
        private analyticsService: GoogleAnalyticsService,
        private config: ConfigService,
        private toastService: NotifyToastService,
        private sessionManagementService: SessionManagementService,
        private store: Store,
        private router: Router
    ) {
        this.currentUser$.pipe(filter(Boolean)).subscribe((user: AceUser) => {
            this.isLoggedIn$.next(user.isLoggedIn);
            if ((user.isLoggedIn && user.isApproved) || user.userType === "GUEST") {
                this.store.dispatch(new UserLoggedIn(user));
            } else {
                this.store.dispatch(new UserLoggedOut());
            }
        });

        this.store
            .select(state => AccountState.smartcards(AccountState.account(state.account)))
            .subscribe((smartcards: AceSmartcard[]) => {
                const user = this.currentUser$.value;
                if (user) {
                    user.smartCards = smartcards;
                }
            });

        this.mobileMenu$.next(window.innerWidth < AccountService.mobileBreakpoint);

        window.onresize = () => this.mobileMenu$.next(window.innerWidth < AccountService.mobileBreakpoint);
    }

    public init() {
        // check if token exist to validate with API
        this.credentialsSigner.isAuthenticatedObservable().subscribe({
            next: token => {
                if (token) {
                    this.whoAmI().subscribe({
                        next: user => {
                            // Server has identified us using cognito details, and returned a user model
                            this.currentUser$.next(user);
                        },
                        error: () => {
                            // Create an initial anonymous user if WHOIAM request errors
                            this.currentUser$.next(new AceAnonymousUser());
                        }
                    });
                } else {
                    this.currentUser$.next(new AceAnonymousUser());
                }
            },
            error: err => {
                // Create an initial anonymous user if isAuthenticatedObservable request errors
                console.log("Error in the isAuthenticatedObservable : ", err);
                this.currentUser$.next(new AceAnonymousUser());
            }
        });

        this.credentialsSigner.authenticatedSessionExpired.pipe(filter(Boolean)).subscribe(() => {
            this.logOut();
            this.sessionManagementService.handleExpiredSession();
        });
    }

    public getBooking(id): Observable<AceUserBooking> {
        let userBooking = this.getCurrentUser().bookings.find(booking => booking.bookingRecordLocator === id);

        if (!userBooking) {
            return this.getBookings().pipe(
                switchMap(user => {
                    userBooking = user.bookings.find(booking => booking.bookingRecordLocator === id);
                    return this.bookingService.retrieveBookingAsUserBooking(id, "", userBooking.context);
                })
            );
        } else {
            return this.bookingService.retrieveBookingAsUserBooking(id, "", userBooking.context);
        }
    }

    public getBookings(refresh: boolean = false) {
        const user = this.getCurrentUser();

        const params = {
            entityName: USER_BOOKING_REQUEST,
            method: "GET",
            data: {
                userId: user.userId
            }
        };

        if (!refresh) {
            // if we have the user bookings do not make another request
            // and return the user
            if (user.bookings.length === user.bookingsCount) {
                return of(user);
            }
        }

        return combineLatest([this.aceCoreApi.call("DataRequest", params).pipe(map(res => res.data)), this.config.config$]).pipe(
            map(([bookings, config]) => {
                user.setUserBookings(bookings, config);
                return user;
            })
        );
    }

    public getCurrentUser() {
        return this.currentUser$.getValue();
    }

    public createGuestUser(email: string, optInForMarketing: boolean = false): Observable<AceUser> {
        return this.login(email, undefined, optInForMarketing);
    }

    public login(email: string, password?: string, optInForMarketing?: boolean): Observable<AceUser> {
        const params = {
            userName: email,
            optInForMarketing
        };

        // Guest logins do not have a password
        if (password) {
            params["password"] = password;
        }

        return combineLatest([
            this.aceCoreApi.call(USER_LOGIN_REQUEST, params).pipe(
                map(res => res.data as DataModel.UserLoginResponse),
                mergeMap(loginResponse => this.credentialsSigner.setCreds(loginResponse.identityId, loginResponse.token).pipe(map(() => loginResponse)))
            ),
            this.config.config$
        ]).pipe(
            map(([data, _]) => {
                if (data.user.type === "GUEST") {
                    data.user.addresses = [];
                }
                return new AceUser(data.user);
            }),
            tap(user => {
                this.analyticsService.userAnalytics(user);
                this.currentUser$.next(user);
                this.store.dispatch(new ClearJourneyState());
            })
        );
    }

    public whoAmI(): Observable<AceUser> {
        return combineLatest([this.aceCoreApi.call(USER_AUTH_REQUEST, {}).pipe(map(response => response.data)), this.config.config$]).pipe(
            map(([user, _]) => {
                if (user.type === "GUEST") {
                    user.addresses = [];
                }
                return new AceUser(user);
            }),
            retry(1),
            tap(user => this.currentUser$.next(user))
        );
    }

    public update(id: number, actions: any[]): Observable<AceUser> {
        return combineLatest([
            this.aceCoreApi.call(USER_UPDATE_REQUEST, {
                userId: id,
                actions
            }),
            this.config.config$
        ]).pipe(
            map(([response, _]) => new AceUser(response.data)),
            tap(user => this.currentUser$.next(user))
        );
    }

    public register(data: DataModel.User): Observable<any> {
        return combineLatest([
            this.aceCoreApi
                .call<DataModel.UserRegisterRequest>(USER_REGISTER_REQUEST, {
                    user: data
                })
                .pipe(
                    map(res => res.data as DataModel.UserRegisterResponse),
                    mergeMap(loginResponse => this.credentialsSigner.setCreds(loginResponse.identityId, loginResponse.token).pipe(map(() => loginResponse)))
                ),
            this.config.config$
        ]).pipe(
            map(([response, _]) => new AceUser(response.user)),
            tap(user => {
                this.currentUser$.next(user);
                this.analyticsService.userAnalytics(user);
            }),
            catchError(error => {
                console.error("There has been an error registering ", error);
                return throwError(() => error);
            })
        );
    }

    public initReset(payload: DataModel.InitResetPasswordRequest): Observable<DataModel.InitResetPasswordResponse> {
        return this.aceCoreApi.call(USER_INIT_RESET_REQUEST, payload).pipe(map(res => res.data as DataModel.InitResetPasswordResponse));
    }

    public resetPassword(payload: DataModel.ResetPasswordRequest): Observable<DataModel.StandardResponse> {
        return this.aceCoreApi.call(USER_RESET_REQUEST, {
            passwordResetToken: payload.passwordResetToken,
            newPassword: payload.newPassword
        });
    }

    public verifyUser(token: string): Observable<string> {
        return this.aceCoreApi
            .call(USER_VERIFY_REQUEST, {
                verificationToken: token
            })
            .pipe(map(res => res.data));
    }

    public verifyEmail(token: string): Observable<string> {
        return this.aceCoreApi
            .call(EMAIL_VERIFY_REQUEST, {
                verificationToken: token
            })
            .pipe(map(res => res.data));
    }

    public logOut(): Observable<boolean> {
        this.currentUser$.next(new AceAnonymousUser());
        this.store.dispatch(new ClearJourneyState());
        this.basketService.removeAllMultipleBookings().subscribe();
        this.credentialsSigner.clearCognito();
        return of(true);
    }

    public claimAccount(data: DataModel.ClaimGuestAccountRequest): Observable<DataModel.StandardResponse> {
        return this.aceCoreApi.call(CLAIM_ACCOUNT_REQUEST, { ...data }).pipe(
            retry(1),
            catchError(error => {
                console.error("There has been an error claimAccount");
                return throwError(() => error);
            })
        );
    }

    public resendVerificationEmail() {
        return this.aceCoreApi.call(RESEND_EMAIL_REQUEST).pipe(
            catchError(() => {
                console.error("There has been an error sending verification");
                return EMPTY;
            })
        );
    }

    public changeEmail(userId: number, email: string) {
        return this.aceCoreApi
            .call(EMAIL_REGISTER_REQUEST, {
                userId,
                email
            })
            .pipe(
                catchError(err => {
                    this.handleError(err);
                    throw err;
                })
            );
    }

    public toggleMobileNav(newStatus: boolean = null) {
        const status = newStatus !== null ? newStatus : !this.mobileNavExpanded$.getValue();
        this.mobileNavExpanded$.next(status);
        if (status) {
            document.querySelector("body").classList.add("is-menu-expanded");
        } else {
            document.querySelector("body").classList.remove("is-menu-expanded");
        }
    }

    public navigateToPreviousPage(queryParams: Params): void {
        const redirectPath = queryParams.redirect;
        const redirectParams = queryParams.redirectParams != null ? JSON.parse(decodeURIComponent(queryParams.redirectParams)) : { ...queryParams };

        delete redirectParams.redirect;

        this.router.navigate([redirectPath], { queryParams: redirectParams });
    }

    private handleError(err: DataModel.StandardError) {
        if (err.errorMessage && err.errorMessage.indexOf("This email address is not available") !== -1) {
            this.toastService.create({
                msg: err.errorMessage,
                timeout: 5000,
                theme: "warning",
                icon: "info"
            });
        }
    }

    public deleteAccount(email: string) {
        return this.aceCoreApi
            .call(DELETE_USER_ACCOUNT_REQUEST, {
                userName: email
            })
            .pipe(map(res => res.data));
    }
}
