import { merge } from "lodash";
import moment from "moment";
import { DeliveryPreferencesHelper } from "../../../shared/utilities/DeliveryPreferencesHelper";
import { AceBookingLeg } from "./ace-booking-leg";
import { AceTicketableFare } from "./ace-ticketable-fare.model";
import { AceTicketingOption } from "./ace-ticketing-option.model";

export class AceBookingOrder {
    public outgoingLeg: AceBookingLeg;
    public returnLeg: AceBookingLeg;
    public originTravelPoint: DataModel.TravelPoint;
    public destinationTravelPoint: DataModel.TravelPoint;
    public expires: moment.Moment;
    public status: DataModel.OrderStatus;
    public orderID: string;
    public dateBooked: moment.Moment;
    public departureDateTime: moment.Moment;
    public arrivalDateTime: moment.Moment;
    public totalPrice: DataModel.Price;
    public fulfillmentInformation: any;
    public ticketingOptions: AceTicketingOption[];
    public ticketableFares: AceTicketableFare[];
    public exchangeableTicketableFareLocators?: string[];
    public selectedTicketingOption: AceTicketingOption;
    public isReturn: boolean = false;
    public isSinglesReturn: boolean = false;
    public isOpenReturn: boolean = false;
    public isSeasons: boolean = false;
    public isOrderAmended: boolean = false;
    public railcardAmount: number = 0;
    public hasGroupsave: boolean = false;
    public internalCancellationReason: string;
    private _order: DataModel.BookingRecordOrder;
    private _ticketingOptionsInfo: any;

    constructor(order?: DataModel.BookingRecordOrder, ticketingOptionsInfo?: any) {
        if (order && ticketingOptionsInfo) {
            this._order = order;
            this._ticketingOptionsInfo = ticketingOptionsInfo;
            this.init();
        }
    }

    public init(): void {
        this.expires = moment(this._order.holdExpiration);
        this.status = this._order.status;
        this.orderID = this._order.orderID;
        this.isOrderAmended = this._order.cancellationReason === "EXCHANGED" ? true : false;
        this.dateBooked = moment(this._order.dateBooked.replace("Z", ""));
        this.internalCancellationReason = this._order.internalCancellationReason;
        this.fulfillmentInformation = this._order.fulfillmentInformation;
        this.ticketingOptions = this._order.ticketingOptions.map(option => new AceTicketingOption(option));
        this.ticketableFares = this._order.ticketableFares.map(fare => new AceTicketableFare(fare));
        if (this._order.exchangeableTicketableFareLocators) {
            this.exchangeableTicketableFareLocators = this._order.exchangeableTicketableFareLocators;
        }

        // if config is available load in delivery options data.
        if (this._ticketingOptionsInfo) {
            this._sortByPriorities(this.ticketingOptions, this._ticketingOptionsInfo.typePriority);
            this._applyConfigDetails(this.ticketingOptions, this._ticketingOptionsInfo.optionDetails);

            if (this.fulfillmentInformation) {
                this.selectedTicketingOption = new AceTicketingOption(this.fulfillmentInformation.selectedTicketingOption);
                this._applyConfigDetails([this.selectedTicketingOption], this._ticketingOptionsInfo.optionDetails);
                this._applyOrderSelection(this.ticketingOptions, this.fulfillmentInformation.selectedTicketingOption.code);
            }
        }

        // check the type of order
        if (this.isSeasonBooking()) {
            this.isSeasons = true;

            this.originTravelPoint = this.ticketableFares[0].fareOrigin;
            this.destinationTravelPoint = this.ticketableFares[0].fareDestination;

            this.departureDateTime = moment(this.ticketableFares[0].fareStartDateTime);
            this.arrivalDateTime = moment(this.ticketableFares[0].fareExpirationDateTime);
            this.totalPrice = this.ticketableFares[0].totalPrice;
        } else {
            this.isSeasons = false;

            const outgoingSegments = this._order.travelSegments.filter(segment => segment.legGrouping === "1");

            if (outgoingSegments.length > 0) {
                this.outgoingLeg = new AceBookingLeg(this._order.orderID + "_1", outgoingSegments, this._order.ticketableFares);
            }

            const returnSegments = this._order.travelSegments.filter(segment => segment.legGrouping === "2");

            if (returnSegments.length > 0) {
                this.returnLeg = new AceBookingLeg(this._order.orderID + "_2", returnSegments, this._order.ticketableFares);
                this.isReturn = true;
                this.isSinglesReturn = this.returnLeg.fares.some(leg => leg.isSingle) && this.outgoingLeg !== undefined;
            }

            this.originTravelPoint = this.outgoingLeg ? this.outgoingLeg.originTravelPoint : this.returnLeg ? this.returnLeg.originTravelPoint : null;
            this.destinationTravelPoint = this.outgoingLeg ? this.outgoingLeg.destinationTravelPoint : this.returnLeg ? this.returnLeg.destinationTravelPoint : null;

            if (this.outgoingLeg) {
                this.totalPrice = {
                    currency: this.outgoingLeg.totalPrice.currency,
                    isEstimated: this.outgoingLeg.totalPrice.isEstimated,
                    value: this.outgoingLeg.totalPrice.value + (this.returnLeg && this.isSinglesReturn ? this.returnLeg.totalPrice.value : 0)
                };
            } else if (this.returnLeg) {
                this.totalPrice = {
                    currency: this.returnLeg.totalPrice.currency,
                    isEstimated: this.returnLeg.totalPrice.isEstimated,
                    value: this.returnLeg.totalPrice.value
                };
            }
        }

        this.railcardAmount = this.ticketableFares.filter(fare => fare.fareQualifiers.length !== 0 && fare.hasGroupsave === false).length;

        this.hasGroupsave = this.ticketableFares && this.ticketableFares.some(fares => fares.hasGroupsave);
    }

    /**
     * apply ticketing options config data to the ticketing options on order
     * @param {AceTicketingOption[]} items
     * @param options
     */
    private _applyConfigDetails(items: AceTicketingOption[], options: any[]): void {
        let valueDocument = "";
        for (let item of items) {
            const details = options.find(option => option.code === item.code);
            if (details) {
                item = merge(item, details);

                if (DeliveryPreferencesHelper.isOnDeparture(item)) {
                    const lastItem = item.details.listItems[item.details.listItems.length - 1];
                    item.details.listItems.pop();

                    if (this.fulfillmentInformation && this.fulfillmentInformation.valueDocument) {
                        valueDocument = this.fulfillmentInformation.valueDocument.valueDocumentLocator;
                    }

                    item.details.listItems.push(`${lastItem} ${valueDocument}`);
                }
            }
        }
    }

    private _sortByPriorities(items: AceTicketingOption[], priority: string[]): void {
        for (const type of priority) {
            this._sortByType(items, type);
        }
    }

    private _sortByType(items: AceTicketingOption[], type: string): void {
        for (let i = 0; i < items.length; i++) {
            if (items[i].type === type) {
                items.unshift(items.splice(i, 1)[0]);
            }
        }
    }

    /**
     * set ticketingOption on order to selected
     * @param {AceTicketingOption[]} ticketingOptions
     * @param {DataModel.TicketingOptionCode} codeID
     */
    private _applyOrderSelection(ticketingOptions: AceTicketingOption[], codeID: DataModel.TicketingOptionCode): boolean {
        if (ticketingOptions.length) {
            const option = ticketingOptions.find(it => it.code === codeID);

            if (option) {
                option.isSelected = true;
                return option.isSelected;
            }
        }
    }

    /**
     * an order is a season if it has a single ticketable fare, with all prices of type TRAVEL_PASS
     * and/or all fareNames starting with SEASON
     * @returns {boolean}
     */
    private isSeasonBooking(): boolean {
        if (this._order.ticketableFares.length !== 1) {
            return false;
        }

        const fare = this._order.ticketableFares[0];
        const hasOnlySeasonPrices = fare.prices.every(price => price.type === "TRAVEL_PASS");
        const hasOnlySeasonFareNames = fare.passengerReferences[0].fareCodes.every(fareCode => fareCode.fareClass.startsWith("SEASON"));

        if (hasOnlySeasonPrices || hasOnlySeasonFareNames) {
            return true;
        }

        return false;
    }
}
