import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import moment from "moment-timezone";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, filter, map, mergeMap, take } from "rxjs/operators";
import { IQueryParams } from "../shared/models/interfaces/IQueryParams";
import { SilverRailRailcard } from "../shared/models/api/railcard";
import { ConfigState } from "../shared/state/config/config.state";
import { RailcardsState } from "../shared/state/railcards/railcards.state";
import { SearchTypes } from "../shared/models/entities/journey-search";
import { LoggerService } from "./logger.service";
import { StationService } from "./station.service";

@Injectable({
    providedIn: "root"
})
export class RouteValidatorService {
    public railcards$: Observable<SilverRailRailcard[]>;

    constructor(private _stationService: StationService, private _logger: LoggerService, private _store: Store) {
        this._logger.loggerContext = "RouteValidatorService";
        this.railcards$ = this._store.select(state => RailcardsState.railcards(state.railcards));
    }

    public validateRouteParams(params: IQueryParams, type: SearchTypes): Observable<boolean> {
        const validatorsForType: boolean[] = [];
        const asyncValidatorsForType: Observable<boolean>[] = [];
        switch (type) {
            case "SEASON":
                validatorsForType.push(this.validatePassengerParam(params.adults, params.children), this.validateDateParam(params.startDate, params.endDate, type));
                break;
            case "STANDARD":
                validatorsForType.push(
                    this.validateInboundParam(params.inbound),
                    this.validateTimeTypeParam(params.outboundTimeType),
                    this.validatePassengerParam(params.adults, params.children),
                    this.validateDateParam(params.outboundTime, params.inboundTime)
                );
                asyncValidatorsForType.push(this.validateRailcardsParam(params.railcards));
                break;
            default:
                break;
        }

        return combineLatest([
            this.validateStationsCodes(params.origin),
            this.validateStationsCodes(params.destination),
            this.validateStationsCodes(params.via),
            this.validateStationsCodes(params.avoid),
            ...asyncValidatorsForType
        ]).pipe(map(values => [...values, ...validatorsForType].every(Boolean)));
    }

    public validateCjAffiliate(params: IQueryParams): IQueryParams {
        const cjevent = params["CJEVENT"];
        const pattern = RegExp(/^[a-zA-Z0-9]{0,64}$/);
        return cjevent && pattern.test(cjevent) ? { ...params, cjevent } : params;
    }

    private validateDateParam(outboundTime, inboundTime, type?: SearchTypes): boolean {
        const maxDaysToStartFromToday = this._store.selectSnapshot(state => ConfigState.bookingWindow(state.config));
        const DATE_STRING_FORMAT: string = type === "STANDARD" ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD";
        const today: any = moment();
        const maxStartDate: any = moment(today).clone().add(maxDaysToStartFromToday, "days").tz('Europe/London').format('YYYY-MM-DDTHH:mm:ss');

        const endDate: any =
            type === "STANDARD" ? moment(today).clone().add(maxStartDate, "days") : moment(outboundTime, DATE_STRING_FORMAT).clone().add(1, "year").subtract(1, "day");

        if (outboundTime && !moment(outboundTime, DATE_STRING_FORMAT).isValid()) {
            return false;
        }

        if (outboundTime && moment(outboundTime).isBefore(today, "day")) {
            return false;
        }

        if (outboundTime && moment(outboundTime).isAfter(endDate, "day")) {
            return false;
        }

        if (inboundTime && !moment(inboundTime, DATE_STRING_FORMAT).isValid()) {
            return false;
        }

        if (outboundTime && moment(outboundTime).date() === moment(maxStartDate).date() && moment(outboundTime).isAfter(maxStartDate, "hour")) {
            return false;
        }

        if (inboundTime && moment(inboundTime).isBefore(today, "day")) {
            return false;
        }

        if (inboundTime && moment(inboundTime).isAfter(endDate, "day")) {
            return false;
        }

        if (inboundTime && moment(inboundTime).date() === moment(maxStartDate).date() && moment(inboundTime).isAfter(maxStartDate, "hour")) {
            return false;
        }

        return true;
    }

    private validateInboundParam(inbound): boolean {
        if (inbound && !(inbound === "true" || inbound === "false")) {
            return false;
        }

        return true;
    }

    private validatePassengerParam(adults, children): boolean {
        adults = parseInt(adults, 10);
        children = parseInt(children, 10);
        const passengers = Math.max(0, adults + children);

        if (passengers > this._store.selectSnapshot(state => ConfigState.maxPassenger(state.config))) {
            return false;
        }

        if (Math.sign(adults) === -1 || adults !== +adults) {
            return false;
        }

        if (Math.sign(children) === -1 || children !== +children) {
            return false;
        }

        return true;
    }

    private validateTimeTypeParam(outboundTimeType: string): boolean {
        const timeType = ["DEPARTURE", "ARRIVAL"];

        if (outboundTimeType && timeType.includes(outboundTimeType.toLocaleUpperCase()) === false) {
            return false;
        }

        return true;
    }

    private validateRailcardsParam(param: string): Observable<boolean> {
        if (param != null) {
            const query = JSON.parse(param);

            return this.railcards$.pipe(
                filter(it => it && it.length > 0),
                take(1),
                mergeMap(validSrRailcards => {
                    const railcardsInvalid = query.some(
                        railcard =>
                            validSrRailcards.map(railcards => railcards.code).includes(railcard.Code) === false ||
                            ["Code", "Number", "Type"].every(property => railcard.hasOwnProperty(property) === false) ||
                            railcard.Type !== "DISCOUNT_CARD"
                    );

                    return of(!railcardsInvalid);
                })
            );
        }

        return of(true);
    }

    private validateStationsCodes(stationCode): Observable<boolean> {
        if (stationCode) {
            return this._stationService.fetchStation(stationCode).pipe(
                map(station => station.code === stationCode),
                catchError(() => of(false))
            );
        }

        return of(true);
    }
}
