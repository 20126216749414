import { CommonModule, CurrencyPipe, DatePipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { A11yModule } from "@angular/cdk/a11y";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { PlatformModule } from "@angular/cdk/platform";
import { AccordionComponent } from "./components/accordion/accordion.component";
import { AddressFinderComponent } from "./components/address-finder/address-finder.component";
import { AddressFormGroupComponent } from "./components/address-formgroup/address-formgroup.component";
import { TicketPreviewComponent } from "./components/ticket-preview/ticket-preview.component";
import { BookingStepsComponent } from "./components/booking-steps/booking-steps.component";
import { ButtonComponent } from "./components/button/button.component";
import { CalendarCardComponent } from "./components/calendar-card/calendar-card.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { CardRequestComponent } from "./components/card-request/card-request.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { ClaimAccountFormComponent } from "./components/claim-account-form/claim-account-form.component";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { ErrorSignComponent } from "./components/error-sign/error-sign.component";
import { FormErrorComponent } from "./components/form-error/form-error.component";
import { ItsoLoginComponent } from "./components/itso-login/itso-login.component";
import { ItsoRegisterComponent } from "./components/itso-register/itso-register.component";
import { JourneyAdjustComponent } from "./components/journey-adjust/journey-adjust.component";
import { JourneyLegComponent } from "./components/journey-leg/journey-leg.component";
import { JourneySummaryLegComponent } from "./components/journey-summary-leg/journey-summary-leg.component";
import { JourneySummaryComponent } from "./components/journey-summary/journey-summary.component";
import { LoadingIndicatorComponent } from "./components/loading-indicator/loading-indicator.component";
import { MessageBoxComponent } from "./components/message-box/message-box.component";
import { NotificationBoxComponent } from "./components/notification-box/notification-box.component";
import { NotifyToastComponent } from "./components/notify-toast/notify-toast.component";
import { NotifyToastContentComponent } from "./components/notify-toast/toast/notify-toast-content.component";
import { NumericalStepperComponent } from "./components/numerical-stepper/numerical-stepper.component";
import { NuscardPickerComponent } from "./components/nuscard-picker/nuscard-picker.component";
import { PassengerPickerComponent } from "./components/passenger-picker/passenger-picker.component";
import { PromoBadgeComponent } from "./components/promo-badge/promo-badge.component";
import { QttSeasonTicketComponent } from "./components/qtt-st/qtt-st.component";
import { QttStationPickersComponent } from "./components/qtt-station-pickers/qtt-station-pickers.component";
import { QttToggleComponent } from "./components/qtt-toggle/qtt-toggle.component";
import { QTTComponent } from "./components/qtt/qtt.component";
import { RadioComponent } from "./components/radio/radio.component";
import { RailcardPickerComponent } from "./components/railcard-picker/railcard-picker.component";
import { SeasonTicketComponent } from "./components/season-ticket/season-ticket.component";
import { SelectComponent } from "./components/select/select.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { SpinnerRingComponent } from "./components/spinner-ring/spinner-ring.component";
import { StationPickerComponent } from "./components/station-picker/station-picker.component";
import { StnrStationPickerComponent } from "./components/stnr-station-picker/stnr-station-picker.component";
import { SwitchComponent } from "./components/switch/switch.component";
import { TravelTimeOptionComponent } from "./components/travel-time-option/travel-time-option.component";
import { DisableCopyPasteDirective } from "./directives/disable-copy-paste.directive";
import { HasFeatureDirective } from "./directives/hasFeature.directive";
import { FeatureEnabledGuard } from "./guards/feature-enabled.guard";
import { RegisteredSmartcardGuard } from "./guards/registered-smartcard.guard";
import { SeasonTicketsEnabledGuard } from "./guards/season-tickets-enabled.guard";
import { SingleBookingOnlyGuard } from "./guards/single-booking-only.guard";
import { AceFeePipe } from "./pipes/ace-fee.pipe";
import { AmenitiesPipe } from "./pipes/amenities.pipe";
import { CapitalizePipe } from "./pipes/capitalize.pipe";
import { CarrierNamePipe } from "./pipes/carrier-name.pipe";
import { ContentByKeyPipe } from "./pipes/content-by-key.pipe";
import { CRSCode } from "./pipes/crs-code.pipe";
import { CustomCurrencyPipe } from "./pipes/customCurrency.pipe";
import { DeliveryIconByCodePipe } from "./pipes/delivery-icon-by-code.pipe";
import { DeliveryOptionPipe } from "./pipes/delivery-option.pipe";
import { DeliveryIconByTypePipe } from "./pipes/delivery-icon-by-type.pipe";
import { FareClassPipe } from "./pipes/fare-class.pipe";
import { FareNamePipe } from "./pipes/fare-type-name.pipe";
import { InCopyVariablesPipe } from "./pipes/inCopyVariables.pipe";
import { MomentPipe } from "./pipes/moment.pipe";
import { NusFormatPipe } from "./pipes/nus-format.pipe";
import { OrderStatusPipe } from "./pipes/order-status.pipe";
import { PluralisePipe } from "./pipes/pluralise.pipe";
import { PromotionPipe } from "./pipes/promotion.pipe";
import { RailcardDiscountPipe } from "./pipes/railcard-discount.pipe";
import { RailcardPipe } from "./pipes/railcard.pipe";
import { SeatPreferencePipe } from "./pipes/seat-preference.pipe";
import { ServiceClassPipe } from "./pipes/service-class.pipe";
import { SmartcardFormatPipe } from "./pipes/smartcard-format.pipe";
import { SmartcardsStatusFilterPipe } from "./pipes/smartcards-filter-status.pipe";
import { SmartcardsTypeFilterPipe } from "./pipes/smartcards-filter-type.pipe";
import { StationPipe } from "./pipes/station.pipe";
import { StripHtmlPipe } from "./pipes/strip-html.pipe";
import { CalendarDayDirective } from "./directives/calendar-day.directive";
import { PriceLabelPipe } from "./pipes/price-label.pipe";
import { SelectionDescriptionPipe } from "./pipes/selection-description.pipe";
import { FocusInvalidFieldDirective } from "./directives/focus-invalid-field.directive";
import { DepartureArrivalDescriptionPipe } from "./pipes/departure-arrival-description.pipe";
import { PriceSeasonLabelPipe } from "./pipes/price-season-label.pipe";
import { AnnounceDirective } from "./directives/announce.directive";
import { SplitWithCommaPipe } from "./pipes/split-with-comma";
import { ViewportTrackerDirective } from "./directives/viewport-tracker.directive";
import { TooltipComponent } from "./components/tooltip/tooltip.component";
import { ChunkPipe } from "./pipes/chunk.pipe";

@NgModule({
    declarations: [
        AccordionComponent,
        AceFeePipe,
        SmartcardFormatPipe,
        AddressFinderComponent,
        TicketPreviewComponent,
        BookingStepsComponent,
        ButtonComponent,
        CRSCode,
        CalendarCardComponent,
        CalendarComponent,
        CapitalizePipe,
        CarrierNamePipe,
        CheckboxComponent,
        ClaimAccountFormComponent,
        ContentByKeyPipe,
        DeliveryIconByCodePipe,
        DeliveryIconByTypePipe,
        DeliveryOptionPipe,
        DropdownComponent,
        FareClassPipe,
        FareNamePipe,
        InCopyVariablesPipe,
        JourneyAdjustComponent,
        JourneyLegComponent,
        JourneySummaryComponent,
        JourneySummaryLegComponent,
        LoadingIndicatorComponent,
        MessageBoxComponent,
        MomentPipe,
        NotificationBoxComponent,
        NotifyToastComponent,
        NotifyToastContentComponent,
        NumericalStepperComponent,
        NuscardPickerComponent,
        OrderStatusPipe,
        PassengerPickerComponent,
        PluralisePipe,
        PromoBadgeComponent,
        QTTComponent,
        QttSeasonTicketComponent,
        QttToggleComponent,
        RadioComponent,
        RailcardDiscountPipe,
        RailcardPickerComponent,
        RailcardPipe,
        SeasonTicketComponent,
        SeatPreferencePipe,
        SelectComponent,
        ServiceClassPipe,
        SpinnerComponent,
        SpinnerRingComponent,
        StationPickerComponent,
        StnrStationPickerComponent,
        StationPipe,
        StripHtmlPipe,
        SwitchComponent,
        TooltipComponent,
        TravelTimeOptionComponent,
        NusFormatPipe,
        SmartcardsTypeFilterPipe,
        AddressFormGroupComponent,
        CardRequestComponent,
        HasFeatureDirective,
        DisableCopyPasteDirective,
        FormErrorComponent,
        ItsoLoginComponent,
        ItsoRegisterComponent,
        SmartcardsStatusFilterPipe,
        ErrorSignComponent,
        PromotionPipe,
        QttStationPickersComponent,
        CustomCurrencyPipe,
        AmenitiesPipe,
        SplitWithCommaPipe,
        CalendarDayDirective,
        PriceLabelPipe,
        SelectionDescriptionPipe,
        FocusInvalidFieldDirective,
        DepartureArrivalDescriptionPipe,
        PriceSeasonLabelPipe,
        AnnounceDirective,
        ViewportTrackerDirective,
        ChunkPipe
    ],
    exports: [
        AccordionComponent,
        AceFeePipe,
        AddressFinderComponent,
        TicketPreviewComponent,
        BookingStepsComponent,
        ButtonComponent,
        CRSCode,
        CalendarCardComponent,
        CalendarComponent,
        CapitalizePipe,
        CarrierNamePipe,
        CheckboxComponent,
        ClaimAccountFormComponent,
        ContentByKeyPipe,
        DeliveryIconByCodePipe,
        DeliveryIconByTypePipe,
        DeliveryOptionPipe,
        DropdownComponent,
        FareClassPipe,
        FareNamePipe,
        InCopyVariablesPipe,
        JourneyAdjustComponent,
        JourneyLegComponent,
        JourneySummaryComponent,
        JourneySummaryLegComponent,
        LoadingIndicatorComponent,
        MessageBoxComponent,
        MomentPipe,
        NotificationBoxComponent,
        NotifyToastComponent,
        NotifyToastContentComponent,
        NumericalStepperComponent,
        NuscardPickerComponent,
        OrderStatusPipe,
        PassengerPickerComponent,
        PluralisePipe,
        PromoBadgeComponent,
        QTTComponent,
        QttSeasonTicketComponent,
        QttToggleComponent,
        RadioComponent,
        RailcardDiscountPipe,
        RailcardPickerComponent,
        RailcardPipe,
        SeasonTicketComponent,
        SeatPreferencePipe,
        SelectComponent,
        ServiceClassPipe,
        SpinnerComponent,
        SpinnerRingComponent,
        StationPickerComponent,
        StnrStationPickerComponent,
        StationPipe,
        ChunkPipe,
        StripHtmlPipe,
        SwitchComponent,
        TooltipComponent,
        TravelTimeOptionComponent,
        NusFormatPipe,
        SmartcardFormatPipe,
        SmartcardsTypeFilterPipe,
        AddressFormGroupComponent,
        CardRequestComponent,
        HasFeatureDirective,
        DisableCopyPasteDirective,
        FormErrorComponent,
        ItsoLoginComponent,
        ItsoRegisterComponent,
        SmartcardsStatusFilterPipe,
        ErrorSignComponent,
        PromotionPipe,
        QttStationPickersComponent,
        CustomCurrencyPipe,
        AmenitiesPipe,
        SplitWithCommaPipe,
        PriceLabelPipe,
        SelectionDescriptionPipe,
        FocusInvalidFieldDirective,
        DepartureArrivalDescriptionPipe,
        PriceSeasonLabelPipe,
        AnnounceDirective,
        ViewportTrackerDirective
    ],
    imports: [CommonModule, FormsModule, HttpClientModule, ReactiveFormsModule, RouterModule, A11yModule, ScrollingModule, PlatformModule, ReactiveFormsModule],
    providers: [CurrencyPipe, PriceLabelPipe, SelectionDescriptionPipe, PriceSeasonLabelPipe, DepartureArrivalDescriptionPipe, PluralisePipe, StationPipe, DatePipe, ChunkPipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [SeasonTicketsEnabledGuard, SingleBookingOnlyGuard, FeatureEnabledGuard, RegisteredSmartcardGuard]
        };
    }
}
