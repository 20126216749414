import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "chunkSize"
})
export class ChunkPipe implements PipeTransform {
    transform(array: any[], chunkSize: number): any[][] {
        if (!array.length) {
            return [];
        }
        return array.reduce((acc, _, i) => (i % chunkSize ? acc : [...acc, array.slice(i, i + chunkSize)]), []);
    }
}
