import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { filter } from "rxjs/operators";
import moment from "moment";
import { environment } from "../../environments/environment";
import { AceBooking } from "../shared/models/ace/ace-booking";
import { AceUser } from "../shared/models/ace/ace-user.model";
import { CompleteTransactionAnalyticsModel } from "../shared/models/complete-transaction-analytics.model";
import { nreActionType, nreEventType } from "../shared/models/entities/nre";
import { IAnalyticsProducts } from "../shared/models/interfaces/IAnalytics";
import { INREAnalytics } from "../shared/models/interfaces/INREAnalytics";
import { IQueryParams } from "../shared/models/interfaces/IQueryParams";
import { ITicketAnalytics } from "../shared/models/interfaces/ITicketAnalytics";
import { IWindow } from "../shared/models/interfaces/IWindow";
import { SearchAnalyticsModel } from "../shared/models/search-analytics.model";
import { StationApiResponse } from "../shared/models/entities/station";
import { MetadataState } from "../shared/state/metadata/metadata.state";
import { RailcardsState } from "../shared/state/railcards/railcards.state";
import { IEcommerceAnalytics } from "../shared/models/interfaces/IEcommerceAnalytics";
import { AceBookingOrder } from "../shared/models/ace/ace-booking-order";
import { StationService } from "./station.service";
import { WINDOW } from "./window.service";
import { format } from "date-fns";

@Injectable({
    providedIn: "root"
})
export class GoogleAnalyticsService {
    constructor(
        private stationService: StationService,
        @Inject(WINDOW) private window: IWindow,
        private store: Store
    ) {}
    public gtmTrackPage(location: Location): void {
        if (this.window.dataLayer) {
            this.window.dataLayer.push({
                angularPage: `${location.pathname + location.hash.split(";")[0]}`,
                hashString: location.hash,
                event: "screen-change"
            });
        }
    }

    public gtmTrackEvent(data: any): void {
        if (this.window.dataLayer) {
            this.window.dataLayer.push(data);
        }
    }

    public confirmationAnalytics(booking: AceBooking, tickets: any) {
        const products: IAnalyticsProducts[] = booking.bookingOrders
            .map(order => {
                const res: IAnalyticsProducts[] = [];

                if (order.isSinglesReturn) {
                    for (let i = 0; i <= 1; i++) {
                        res.push({
                            sku: order.orderID,
                            name:
                                i === 0
                                    ? `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`
                                    : `${this.getStationName(order.destinationTravelPoint.code)} to ${this.getStationName(order.originTravelPoint.code)}`,
                            category: i === 0 ? order.outgoingLeg.fares[0].fareDisplayName : order.returnLeg.fares[0].fareDisplayName,
                            price: Number(((i === 0 ? order.outgoingLeg.totalPrice.value : order.returnLeg.totalPrice.value) / booking.passengers.length).toFixed(2)),
                            quantity: booking.passengers.length
                        });
                    }
                } else {
                    res.push({
                        sku: order.orderID,
                        name: `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`,
                        category: order.isSeasons ? order.ticketableFares[0].fareDisplayName : order.outgoingLeg.fares[0].fareDisplayName,
                        price: Number((order.totalPrice.value / booking.passengers.length).toFixed(2)),
                        quantity: booking.passengers.length
                    });
                }

                return res;
            })
            .reduce((a, b) => a.concat(b), []);

        const sales = booking.bookingTotal.value;
        const productcount = booking.bookingOrders.length;
        let ordItems = [];
        let sv2;

        if (booking.bookingOrders.length > 0) {
            booking.bookingOrders.map(ord => {
                const productsales = ord.totalPrice.value;
                const sv2 = ord.outgoingLeg.travelSegments[0].operatingCarrier.toString();
                const sv3 = this.getStationName(ord.destinationTravelPoint.code).toString();
                const sv4 = this.getStationName(ord.originTravelPoint.code).toString();
                const sv5 = format(new Date(ord.outgoingLeg.departureDateTime.toString()), "yyyy-MM-dd'T'HH:mm:ss").toString();
                const sv6 = ord.outgoingLeg.fares[0].cabinClass.toString();
                let order = {
                    productsales: productsales,
                    sv2: sv2.toString(),
                    sv3: sv3.toString(),
                    sv4: sv4.toString(),
                    sv5: sv5.toString(),
                    sv6: sv6.toString()
                };
                ordItems.push(order);
            });
        }

        this.gtmTrackEvent({
            order: {
                sales: sales,
                basketsize: productcount,
                bookingReference: booking.bookingID,
                itms: ordItems
            },
            event: "adform_purchase"
        });

        const ticketAnalytics: ITicketAnalytics[] = [];

        booking.bookingOrders.forEach(order => {
            if (order.outgoingLeg) {
                ticketAnalytics.push({
                    origin: this.getStationName(order.outgoingLeg.originTravelPoint.code),
                    destination: this.getStationName(order.outgoingLeg.destinationTravelPoint.code),
                    ticketType: order.outgoingLeg.fares[0].fareDisplayName,
                    operator: order.outgoingLeg.travelSegments.every(travelSegment => travelSegment.carrierName === "GrandCentral") ? "GC" : "non-GC"
                });
            }

            if (order.returnLeg) {
                ticketAnalytics.push({
                    origin: this.getStationName(order.returnLeg.originTravelPoint.code),
                    destination: this.getStationName(order.returnLeg.destinationTravelPoint.code),
                    ticketType: order.returnLeg.fares[0].fareDisplayName,
                    operator: order.returnLeg.travelSegments.every(travelSegment => travelSegment.carrierName === "GrandCentral") ? "GC" : "non-GC"
                });
            }
        });

        this.gtmTrackEvent({
            tickets: ticketAnalytics,
            ticketsSold: ticketAnalytics.length
        });
    }

    public bookingAnalytics(booking: AceBooking) {
        const products: IAnalyticsProducts[] = booking.bookingOrders
            .map(order => {
                const res: IAnalyticsProducts[] = [];

                if (order.isSinglesReturn) {
                    for (let i = 0; i <= 1; i++) {
                        res.push({
                            sku: order.orderID,
                            name:
                                i === 0
                                    ? `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`
                                    : `${this.getStationName(order.destinationTravelPoint.code)} to ${this.getStationName(order.originTravelPoint.code)}`,
                            category: i === 0 ? order.outgoingLeg.fares[0].fareDisplayName : order.returnLeg.fares[0].fareDisplayName,
                            price: Number(((i === 0 ? order.outgoingLeg.totalPrice.value : order.returnLeg.totalPrice.value) / booking.passengers.length).toFixed(2)),
                            quantity: booking.passengers.length
                        });
                    }
                } else {
                    res.push({
                        sku: order.orderID,
                        name: `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`,
                        category: order.isSeasons ? order.ticketableFares[0].fareDisplayName : order.outgoingLeg.fares[0].fareDisplayName,
                        price: Number((order.totalPrice.value / booking.passengers.length).toFixed(2)),
                        quantity: booking.passengers.length
                    });
                }

                return res;
            })
            .reduce((a, b) => a.concat(b), []);

        this.gtmTrackEvent({
            transactionId: booking.bookingID,
            transactionTotal: booking.bookingTotal.value,
            transactionTax: 0,
            transactionShipping: booking.bookingOrders[0].selectedTicketingOption.fee.value,
            transactionProducts: products,
            transactionTicketsSold: booking.bookingOrders.length,
            event: "transactionDataPushed"
        });

        const ticketAnalytics: ITicketAnalytics[] = [];

        booking.bookingOrders.forEach(order => {
            if (order.outgoingLeg) {
                ticketAnalytics.push({
                    origin: this.getStationName(order.outgoingLeg.originTravelPoint.code),
                    destination: this.getStationName(order.outgoingLeg.destinationTravelPoint.code),
                    ticketType: order.outgoingLeg.fares[0].fareDisplayName,
                    operator: order.outgoingLeg.travelSegments.every(travelSegment => travelSegment.carrierName === "GrandCentral") ? "GC" : "non-GC"
                });
            }

            if (order.returnLeg) {
                ticketAnalytics.push({
                    origin: this.getStationName(order.returnLeg.originTravelPoint.code),
                    destination: this.getStationName(order.returnLeg.destinationTravelPoint.code),
                    ticketType: order.returnLeg.fares[0].fareDisplayName,
                    operator: order.returnLeg.travelSegments.every(travelSegment => travelSegment.carrierName === "GrandCentral") ? "GC" : "non-GC"
                });
            }
        });

        this.gtmTrackEvent({
            tickets: ticketAnalytics,
            ticketsSold: ticketAnalytics.length
        });
    }

    public metadataAnalytics(): void {
        const context = this.store.selectSnapshot(state => MetadataState.deviceName(state.metadata));

        this.gtmTrackEvent({
            event: "metadata",
            metadata: {
                toc: environment.tocId,
                deviceType: context
            }
        });
    }

    public successfulTransactionAnalytics(booking: AceBooking): void {
        if (booking != null) {
            booking.bookingOrders.forEach(order => {
                this.gtmTrackEvent({
                    event: "successful-transaction",
                    "double-click-string": new CompleteTransactionAnalyticsModel(booking, order).getCompletionData()
                });
            });

            this.gtmTrackEvent({
                event: "orders",
                "station-codes": booking.bookingOrders.map(order => new CompleteTransactionAnalyticsModel(booking, order).getStationCodes()),
                operator: booking.bookingOrders.map(order => new CompleteTransactionAnalyticsModel(booking, order).getOperatingCarrier(order))
            });
        }
    }

    public userAnalytics(user: AceUser): void {
        if (user != null) {
            this.gtmTrackEvent({
                userId: user.userId,
                userType: user.userType,
                event: "userId"
            });
        }
    }

    public nreAnalytics(nreAction: nreActionType, data: INREAnalytics): void {
        const deviceName = this.store.selectSnapshot(state => MetadataState.deviceName(state.metadata));

        const NOT_APPLICABLE = "N/A";
        let nreEvent: nreEventType = null;

        const payload: INREAnalytics = {
            ...data,
            hostname: window.location.hostname,
            referrer: deviceName,
            origin: data.origin.length > 3 ? data.origin.substring(2) : data.origin,
            destination: data.destination.length > 3 ? data.destination.substring(2) : data.destination
        };

        // Encode error message
        if (data.errormsg) {
            payload.errormsg = data.errormsg.toUpperCase() !== NOT_APPLICABLE ? `${encodeURI(data.errormsg)}-${data.origin}|${data.destination}` : NOT_APPLICABLE;
        }

        // Encode visible error message
        if (data.pagenotif) {
            payload.pagenotif = data.pagenotif.toUpperCase() !== NOT_APPLICABLE ? encodeURI(data.pagenotif) : NOT_APPLICABLE;
        }

        if (nreAction === nreActionType.REDIRECTION && data.errormsg && data.errormsg.length > 0) {
            nreEvent = nreEventType.NRE_FAILURE;
        }

        if (nreAction === nreActionType.PURCHASE) {
            nreEvent = nreEventType.NRE_SUCCESS;
        }

        if (nreEvent && payload) {
            this.gtmTrackEvent({ event: nreEvent, ...payload });
        }
    }

    public searchAnalytics(params: IQueryParams, searchTypeSource: string): void {
        this.store
            .select(state => RailcardsState.railcards(state.railcards))
            .pipe(filter(it => it && it.length > 0))
            .subscribe(railcards => {
                this.gtmTrackEvent({
                    event: "qtt-search-parameters",
                    "qtt-search-string": new SearchAnalyticsModel(params).getParamData(railcards)
                });
            });

        this.gtmTrackEvent({
            event: "journey-source",
            source: searchTypeSource
        });

        this.gtmTrackEvent({
            departureStation: this.getStationName(params.origin),
            destinationStation: this.getStationName(params.destination)
        });
    }

    private getStationName(code: string): string {
        const snapshot = this.stationService.stationSnapshot.get("") as StationApiResponse[];

        if (snapshot) {
            return snapshot.find(station => station.code === code).name;
        }
    }

    public purchaseAnalytics(booking: AceBooking, affiliated: string): void {
        const purchaseAnalytics: IEcommerceAnalytics[] = booking.bookingOrders
            .map(order => {
                const res: IEcommerceAnalytics[] = [];

                if (order.isSinglesReturn) {
                    for (let i = 0; i <= 1; i++) {
                        res.push({
                            transaction_id: booking.bookingID, // string: unique identifier of the transaction

                            affiliation: affiliated, // string: website hostname

                            value: booking.bookingTotal.value, // number: total transaction amount

                            currency: booking.bookingTotal.currency, // string: currency code

                            coupon: booking.vouchers[0]?.code, // string: discount code applied from /booking/summary page
                            items: [
                                {
                                    item_id: order.orderID,
                                    item_name:
                                        i === 0
                                            ? `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`
                                            : `${this.getStationName(order.destinationTravelPoint.code)} to ${this.getStationName(order.originTravelPoint.code)}`,
                                    currency: booking.bookingTotal.currency,
                                    item_category: i === 0 ? order.outgoingLeg.fares[0].fareDisplayName : order.returnLeg.fares[0].fareDisplayName,
                                    item_category2: i === 0 ? order.outgoingLeg.fares[0].cabinClass : order.returnLeg.fares[0].cabinClass,
                                    item_category3:
                                        i === 0 ? moment(order.outgoingLeg.departureDateTime).format("HH:mm") : moment(order.returnLeg.departureDateTime).format("HH:mm"),
                                    item_category4:
                                        i === 0
                                            ? moment(order.outgoingLeg.departureDateTime).toDate().getFullYear() +
                                              "-" +
                                              moment(order.outgoingLeg.departureDateTime).toDate().getMonth() +
                                              "-" +
                                              moment(order.outgoingLeg.departureDateTime).toDate().getDate()
                                            : moment(order.returnLeg.departureDateTime).toDate().getFullYear() +
                                              "-" +
                                              moment(order.returnLeg.departureDateTime).toDate().getMonth() +
                                              "-" +
                                              moment(order.returnLeg.departureDateTime).toDate().getDate(),
                                    price: Number(((i === 0 ? order.outgoingLeg.totalPrice.value : order.returnLeg.totalPrice.value) / booking.passengers.length).toFixed(2)),
                                    quantity: booking.passengers.length
                                }
                            ]
                        });
                    }
                } else {
                    res.push({
                        transaction_id: booking.bookingID, // string: unique identifier of the transaction

                        affiliation: "chilternrailways.co.uk", // string: website hostname

                        value: booking.bookingTotal.value, // number: total transaction amount

                        currency: booking.bookingTotal.currency, // string: currency code

                        coupon: booking.vouchers[0]?.code, // string: discount code applied from /booking/summary page
                        items: [
                            {
                                item_id: order.orderID,
                                item_name: `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`,
                                currency: booking.bookingTotal.currency,
                                item_category: order.outgoingLeg.fares[0].fareDisplayName,
                                item_category2: order.outgoingLeg.fares[0].cabinClass,
                                item_category3: moment(order.outgoingLeg.departureDateTime).format("HH:mm"),
                                item_category4:
                                    moment(order.outgoingLeg.departureDateTime).toDate().getFullYear() +
                                    "-" +
                                    moment(order.outgoingLeg.departureDateTime).toDate().getMonth() +
                                    "-" +
                                    moment(order.outgoingLeg.departureDateTime).toDate().getDate(),
                                price: Number((order.outgoingLeg.totalPrice.value / booking.passengers.length).toFixed(2)),
                                quantity: booking.passengers.length
                            }
                        ]
                    });
                }

                return res;
            })
            .reduce((a, b) => a.concat(b), []);

        purchaseAnalytics.map(obj => {
            this.gtmTrackEvent({
                event: "purchase",
                ecommerce: obj
            });
        });
    }

    public addToCartAnalytics(booking: AceBooking, eventName: string): void {
        const addRemoveAnalytics: IEcommerceAnalytics[] = booking.bookingOrders
            .map(order => {
                const res: IEcommerceAnalytics[] = [];

                if (order.isSinglesReturn) {
                    for (let i = 0; i <= 1; i++) {
                        res.push({
                            items: [
                                {
                                    item_id: order.orderID,
                                    item_name:
                                        i === 0
                                            ? `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`
                                            : `${this.getStationName(order.destinationTravelPoint.code)} to ${this.getStationName(order.originTravelPoint.code)}`,
                                    currency: booking.bookingTotal.currency,
                                    item_category: i === 0 ? order.outgoingLeg.fares[0].fareDisplayName : order.returnLeg.fares[0].fareDisplayName,
                                    item_category2: i === 0 ? order.outgoingLeg.fares[0].cabinClass : order.returnLeg.fares[0].cabinClass,
                                    item_category3:
                                        i === 0 ? moment(order.outgoingLeg.departureDateTime).format("HH:mm") : moment(order.returnLeg.departureDateTime).format("HH:mm"),
                                    item_category4:
                                        i === 0
                                            ? moment(order.outgoingLeg.departureDateTime).toDate().getFullYear() +
                                              "-" +
                                              moment(order.outgoingLeg.departureDateTime).toDate().getMonth() +
                                              "-" +
                                              moment(order.outgoingLeg.departureDateTime).toDate().getDate()
                                            : moment(order.returnLeg.departureDateTime).toDate().getFullYear() +
                                              "-" +
                                              moment(order.returnLeg.departureDateTime).toDate().getMonth() +
                                              "-" +
                                              moment(order.returnLeg.departureDateTime).toDate().getDate(),
                                    price: Number(((i === 0 ? order.outgoingLeg.totalPrice.value : order.returnLeg.totalPrice.value) / booking.passengers.length).toFixed(2)),
                                    quantity: booking.passengers.length
                                }
                            ]
                        });
                    }
                } else {
                    res.push({
                        items: [
                            {
                                item_id: order.orderID,
                                item_name: `${this.getStationName(order.originTravelPoint.code)} to ${this.getStationName(order.destinationTravelPoint.code)}`,
                                currency: booking.bookingTotal.currency,
                                item_category: order.outgoingLeg.fares[0].fareDisplayName,
                                item_category2: order.outgoingLeg.fares[0].cabinClass,
                                item_category3: moment(order.outgoingLeg.departureDateTime).format("HH:mm"),
                                item_category4:
                                    moment(order.outgoingLeg.departureDateTime).toDate().getFullYear() +
                                    "-" +
                                    moment(order.outgoingLeg.departureDateTime).toDate().getMonth() +
                                    "-" +
                                    moment(order.outgoingLeg.departureDateTime).toDate().getDate(),
                                price: Number((order.outgoingLeg.totalPrice.value / booking.passengers.length).toFixed(2)),
                                quantity: booking.passengers.length
                            }
                        ]
                    });
                }

                return res;
            })
            .reduce((a, b) => a.concat(b), []);

        addRemoveAnalytics.map(obj => {
            this.gtmTrackEvent({
                event: eventName,
                ecommerce: obj
            });
        });
    }
    public removeFromCartAnalytics(bookingOrder: AceBookingOrder, eventName: string): void {
        const res: IEcommerceAnalytics[] = [];

        if (bookingOrder.isSinglesReturn) {
            for (let i = 0; i <= 1; i++) {
                res.push({
                    items: [
                        {
                            item_id: bookingOrder.orderID,
                            item_name:
                                i === 0
                                    ? `${this.getStationName(bookingOrder.originTravelPoint.code)} to ${this.getStationName(bookingOrder.destinationTravelPoint.code)}`
                                    : `${this.getStationName(bookingOrder.destinationTravelPoint.code)} to ${this.getStationName(bookingOrder.originTravelPoint.code)}`,
                            currency: bookingOrder.totalPrice.currency,
                            item_category: i === 0 ? bookingOrder.outgoingLeg.fares[0].fareDisplayName : bookingOrder.returnLeg.fares[0].fareDisplayName,
                            item_category2: i === 0 ? bookingOrder.outgoingLeg.fares[0].cabinClass : bookingOrder.returnLeg.fares[0].cabinClass,
                            item_category3:
                                i === 0 ? moment(bookingOrder.outgoingLeg.departureDateTime).format("HH:mm") : moment(bookingOrder.returnLeg.departureDateTime).format("HH:mm"),
                            item_category4:
                                i === 0
                                    ? moment(bookingOrder.outgoingLeg.departureDateTime).toDate().getFullYear() +
                                      "-" +
                                      moment(bookingOrder.outgoingLeg.departureDateTime).toDate().getMonth() +
                                      "-" +
                                      moment(bookingOrder.outgoingLeg.departureDateTime).toDate().getDate()
                                    : moment(bookingOrder.returnLeg.departureDateTime).toDate().getFullYear() +
                                      "-" +
                                      moment(bookingOrder.returnLeg.departureDateTime).toDate().getMonth() +
                                      "-" +
                                      moment(bookingOrder.returnLeg.departureDateTime).toDate().getDate(),
                            price: Number((i === 0 ? bookingOrder.outgoingLeg.totalPrice.value : bookingOrder.returnLeg.totalPrice.value).toFixed(2)),
                            quantity: 1
                        }
                    ]
                });
            }
        } else {
            res.push({
                items: [
                    {
                        item_id: bookingOrder.orderID,
                        item_name: `${this.getStationName(bookingOrder.originTravelPoint.code)} to ${this.getStationName(bookingOrder.destinationTravelPoint.code)}`,
                        currency: bookingOrder.totalPrice.currency,
                        item_category: bookingOrder.outgoingLeg.fares[0].fareDisplayName,
                        item_category2: bookingOrder.outgoingLeg.fares[0].cabinClass,
                        item_category3: moment(bookingOrder.outgoingLeg.departureDateTime).format("HH:mm"),
                        item_category4:
                            moment(bookingOrder.outgoingLeg.departureDateTime).toDate().getFullYear() +
                            "-" +
                            moment(bookingOrder.outgoingLeg.departureDateTime).toDate().getMonth() +
                            "-" +
                            moment(bookingOrder.outgoingLeg.departureDateTime).toDate().getDate(),
                        price: Number(bookingOrder.outgoingLeg.totalPrice.value.toFixed(2)),
                        quantity: 1
                    }
                ]
            });
        }

        this.gtmTrackEvent({
            event: eventName,
            ecommerce: res[0]
        });
    }
}
